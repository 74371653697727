export { default as About } from './About';
export { default as Contact } from './Contact';
export { default as Faq } from './Faq';
export { default as Features } from './Features';
export { default as Hero } from './Hero';
export { default as Partners } from './Partners';
export { default as Pricings } from './Pricings';
export { default as Reviews } from './Reviews';
export { default as Services } from './Services';
export { default as Users } from './Users';
