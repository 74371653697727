import React from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import etoos_logo from '../../../../src_assets/case_study/etoos_logo.jpeg';
import mobis_logo from '../../../../src_assets/case_study/mobis_logo.jpeg';
import asiana_logo from '../../../../src_assets/case_study/asiana_logo.jpeg';
import hyundai_card_logo from '../../../../src_assets/case_study/hyundai_card_logo.png';
import baemin_logo from '../../../../src_assets/case_study/baemin_logo.gif';
import danawa_logo from '../../../../src_assets/case_study/danawa_logo.webp';
// import wips_logo from '../../../../src_assets/case_study/wips_logo.png';
// import shilla_logo from '../../../../src_assets/case_study/shilla_logo.jpeg';


const mock = [
  {
    logo: etoos_logo,
    name: 'Slack',
  },
  {
    logo: mobis_logo,
    name: 'Mailchimp',
  },
  {
    logo: asiana_logo,
    name: 'Dropbox',
  },
  {
    logo: hyundai_card_logo,
    name: 'Google Drive',
  },
  {
    logo: baemin_logo,
    name: 'Google Ad Manager',
  },
  {
    logo: danawa_logo,
    name: 'Atlassian',
  },
];

const Partners = () => {
  return (
    <Grid container spacing={0}>
      {mock.map((item, index) => (
        <Grid
          item
          container
          key={index}
          xs={4}
          direction={index < 3 ? 'row' : 'row-reverse'}
        >
          <Grid item xs={6}>
            <Avatar
              src={item.logo}
              sx={{
                width: { xs: 60, md: 80 },
                height: { xs: 60, md: 80 },
                padding: 2,
                boxShadow: 4,
                marginTop: 1,
                bgcolor: 'background.paper',
              }}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Partners;
