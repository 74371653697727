import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import project1 from '../../../../src_assets/case_study/project1.png';
import project2 from '../../../../src_assets/case_study/project2.png';
import project3 from '../../../../src_assets/case_study/project3.png';
import project4 from '../../../../src_assets/case_study/project4.png';
import project5 from '../../../../src_assets/case_study/project5.png';
import project6 from '../../../../src_assets/case_study/project6.png';
import project7 from '../../../../src_assets/case_study/project7.png';
import project8 from '../../../../src_assets/case_study/project8.png';
import project9 from '../../../../src_assets/case_study/project9.png';
import project10 from '../../../../src_assets/case_study/project10.png';


import etoos_logo from '../../../../src_assets/case_study/etoos_logo.jpeg';
import mobis_logo from '../../../../src_assets/case_study/mobis_logo.jpeg';
import asiana_logo from '../../../../src_assets/case_study/asiana_logo.jpeg';
import hyundai_card_logo from '../../../../src_assets/case_study/hyundai_card_logo.png';
import baemin_logo from '../../../../src_assets/case_study/baemin_logo.gif';
import danawa_logo from '../../../../src_assets/case_study/danawa_logo.webp';
import wips_logo from '../../../../src_assets/case_study/wips_logo.png';
import shilla_logo from '../../../../src_assets/case_study/shilla_logo.jpeg';

const mock = [
  {
    media: project1,
    companyLogo:
    etoos_logo,
    description:
      'Building a search engine for Etoos.com. Search engine design, administrator screen development, dictionary management.',
  },
  {
    media: project2,
    companyLogo:
    mobis_logo,
    description:
      'Hyundai Mobis chatbot construction project, chatbot backend search engine construction, tens of millions of binary document text extraction and large-capacity index system implementation',
  },
  {
    media: project3,
    companyLogo:
    asiana_logo,
    description:
      'Building Asiana Airlines chatbot, building chatbot using MS LUIS-based bot framework, implementing search engine and natural language processing',
  },
  {
    media: project4,
    companyLogo:
    hyundai_card_logo,
    description:
      'Hyundai Card app benefit search construction, search index design and ranking design, Geo Spatial Search design',
  },
  {
    media: project5,
    companyLogo:
    hyundai_card_logo,
    description:
      'Development of search engine and recommendation engine for Hyundai Card DIVE app',
  },
  {
    media: project6,
    companyLogo:
    etoos_logo,
    description:
      'Establishment of the Etoos data platform, construction of a system that can analyze and visualize various data in the education field',
  },
  {
    media: project7,
    companyLogo:
    baemin_logo,
    description:
      'Baedal Minjok search engine construction, Geo Spatial search and advertising ranking design',
  },
  {
    media: project8,
    companyLogo:
    danawa_logo,
    description:
      'Consulting on a project to change the Lagacy search engine to New ElasticSearch',
  },
  {
    media: project9,
    companyLogo:
      wips_logo,
    description:
      'Establishment of patent search engine, implementation of index design and ranking design',
  },
  {
    media: project10,
    companyLogo:
      shilla_logo,
    description:
      'Ranking tuning and customization for Korean morpheme analyzer',
  },
];

const Stories = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Success stories
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h4'}
          align={'center'}
        >
          See how we are helping teams
          <br />
          and businesses
        </Box>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                borderRadius={2}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.media}
                  title={item.title}
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
                  <Box maxWidth={100} marginY={2}>
                    <Box
                      component="img"
                      height={1}
                      width={1}
                      src={item.companyLogo}
                      alt="..."
                      sx={{
                        filter: mode === 'dark' ? 'contrast(0)' : 'none',
                      }}
                    />
                  </Box>
                  <Typography
                    align={'left'}
                    variant={'body2'}
                    color="textSecondary"
                  >
                    {item.description}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-start'}>
                  <Button
                    size="large"
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    Learn more
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Stories;
