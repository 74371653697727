/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={4}
        >
          <Box>
            <Typography variant={'h5'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Mission
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
            We create solutions and services that contribute to the world by utilizing collection, search, data, and AI technologies.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={4}
        >
          <Box>
            <Typography variant={'h5'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Vision
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
            Operation of search/AI services that communicate with users <br></br>
            Completion of search/AI solutions helpful to businesses
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={4}
        >
          <Box>
            <Typography variant={'h5'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Value
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              Team play <br></br>
              High Responsibility <br></br>
              Technical Deep Dive
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
