import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import search_space_01 from '../../../../src_assets/software/search_space_01.png';
import search_space_02 from '../../../../src_assets/software/search_space_02.png';
const mock = [
  {
    media: search_space_01,
    title: 'Music player',
    price: '$320',
  },
  {
    media: search_space_02,
    title: 'Headphones',
    price: '$450',
  },
  {
    media: 'https://assets.maccarianagency.com/backgrounds/img39.png',
    title: 'Wireless headpohones',
    price: '$280',
  },
  {
    media: 'https://assets.maccarianagency.com/backgrounds/img40.png',
    title: 'Bluetooth headphones',
    price: '$300',
  },
  {
    media: 'https://assets.maccarianagency.com/backgrounds/img41.png',
    title: 'Headphones',
    price: '$280',
  },
  {
    media: 'https://assets.maccarianagency.com/backgrounds/img42.png',
    title: 'Music player',
    price: '$340',
  },
];

const ScreenList = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Products
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Featured products
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          Experience your music like never before. Buy music instruments &
          accessories online.
        </Typography>
        <Box display="flex" justifyContent={'center'} marginTop={2}>
          <Button variant="contained" color="primary" size="large">
            View all
          </Button>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box display={'block'} width={1} height={1}>
              <Box
                component={Card}
                width={1}
                height={1}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  sx={{
                    position: 'relative',
                    height: { xs: 240, sm: 340, md: 280 },
                    overflow: 'hidden',
                    padding: 3,
                    paddingBottom: 0,
                    background: theme.palette.alternate.main,
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    component={'img'}
                    loading="lazy"
                    src={item.media}
                    sx={{
                      '& img': {
                        objectFit: 'contain',
                      },
                    }}
                  />
                  <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    position={'absolute'}
                    top={0}
                    left={0}
                    right={0}
                    padding={2}
                    width={1}
                  >
                    
                  </Box>
                </CardMedia>
                <CardContent>
                  <Typography
                    variant={'h6'}
                    align={'left'}
                    sx={{ fontWeight: 700 }}
                  >
                    {item.title}
                  </Typography>
                  <Box
                    display={'flex'}
                    justifyContent={'flex-start'}
                    marginY={1}
                  >                    
                  </Box>                  
                </CardContent>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ScreenList;
