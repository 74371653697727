export { default as IndexView } from './IndexView';
export { default as FullScreenHeroWithPromoImagesAndTypedText } from './FullScreenHeroWithPromoImagesAndTypedText';
export { default as FullScreenHeroWithImageSlider } from './FullScreenHeroWithImageSlider';
export { default as HeroWithFormAndBackgroundGradient } from './HeroWithFormAndBackgroundGradient';
export { default as HeroForEcommerceApp } from './HeroForEcommerceApp';
export { default as FullScreenHeroWithSubscriptionForm } from './FullScreenHeroWithSubscriptionForm';
export { default as HeroWithIllustrationAndSearchBar } from './HeroWithIllustrationAndSearchBar';
export { default as HeroWithMobileAppScreenshot } from './HeroWithMobileAppScreenshot';
export { default as HeroWithDashboardScreenshotAndCta } from './HeroWithDashboardScreenshotAndCta';
export { default as SimpleHeroWithSearchBox } from './SimpleHeroWithSearchBox';
export { default as SimpleHeroWithCta } from './SimpleHeroWithCta';
export { default as HeroWithIllustrationAndCta } from './HeroWithIllustrationAndCta';
export { default as HeroWithLogoGridAndDesktopScreenshot } from './HeroWithLogoGridAndDesktopScreenshot';
export { default as HeroWithBackgroundVideo } from './HeroWithBackgroundVideo';
export { default as SimpleHeroWithBottomVideo } from './SimpleHeroWithBottomVideo';
export { default as HeroWithPrimaryBackgroundAndDesktopScreenshot } from './HeroWithPrimaryBackgroundAndDesktopScreenshot';
export { default as FullScreenHeroWithLogoGrid } from './FullScreenHeroWithLogoGrid';
export { default as SimpleHeroWithImageAndCtaButtons } from './SimpleHeroWithImageAndCtaButtons';
