import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { CompanyLife, Headline, Team } from './components';

const OurTeamView = () => {
  return (
    <Main>
      <Box>
        <Container>
          <Headline />
        </Container>
        <Container>
          <Team />
        </Container>
        <Container maxWidth={'800px !important'}>
          <Divider />
        </Container>
        <Container>
          <CompanyLife />
        </Container>
        <Container maxWidth={'800px !important'}>
          <Divider />
        </Container>
        <Container maxWidth={'800px !important'}>
          <Divider />
        </Container>

      </Box>
    </Main>
  );
};

export default OurTeamView;
