import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';


import ceo_img from '../../../../src_assets/our_team/ceo_jihoon.png';
import cto_img from '../../../../src_assets/our_team/cto_jehee.png';
import engineer_myeonghee from '../../../../src_assets/our_team/engineer_myeonghee.png';
import engineer_namjoo from '../../../../src_assets/our_team/engineer_namjoo.png';
import engineer_sungmin from '../../../../src_assets/our_team/engineer_sungmin.png';
import engineer_kyungmin from '../../../../src_assets/our_team/engineer_kyungmin.png';
import engineer_yeongjin from '../../../../src_assets/our_team/engineer_yeongjin.png';
import advisor_gyudeok from '../../../../src_assets/our_team/advisor_gyudeok.png';


const mock = [
  {
    name: 'Aiden Jihoon Kim',
    title: 'Co-founder & CEO',
    avatar: ceo_img,
    about:
      'I am an ambitious workaholic, but apart from that, pretty simple person.',
  },
  {
    name: 'Jehee Choi',
    title: 'Co-founder & CTO',
    avatar: cto_img,
    about:
      'Hands-on tech leader,Search engine / recommendation engine / platform business development expert',
  },
  {
    name: 'Sungmin Choi',
    title: 'Senior Search Developer',
    avatar: engineer_sungmin,
    about:
      'Search engine expert and an amazing developer. sometimes a workaholic and sometimes a good cook.',
  },
  {
    name: 'Namjoo Kim',
    title: 'Data Engineer',
    avatar: engineer_namjoo,
    about:
      'He is an outstanding data engineer and collection expert. He is also capable of developing mobile apps and his forte is meticulousness.',
  },
  {
    name: 'Myeonghee Noh',
    title: 'Data Engineer',
    avatar: engineer_myeonghee,
    about:
      'Solver who solves difficult problems. Whatever the problem, she dig into it until the end.',
  },
  {
    name: 'Kyungmin Kim',
    title: 'Data Engineer',
    avatar: engineer_kyungmin,
    about:
      'A very talented developer and data engineer. Excellent problem-solving ability and ability to learn technology.',
  },
  {
    name: 'Yeongjin Sim',
    title: 'Data Engineer',
    avatar: engineer_yeongjin,
    about:
      'Developer with diverse experience and skills in search engines and collection engines.',
  },
  {
    name: 'Gyudeok Oh',
    title: 'Advisor',
    avatar: advisor_gyudeok,
    about:
      'He is the CEO of OnJeonHan(온전한) Co., Ltd. and director of Ohssam Career Research Institute, and is a professional management consultant. He provide generous advice and assistance to PLAT.S',
  },
  

];

const Team = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
          align={'center'}
        >
          Our team
        </Typography>
        <Typography fontWeight={700} variant={'h5'} align={'center'}>
          Trust the professionals
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Box
              component={Card}
              boxShadow={2}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <CardContent>
                <Box
                  component={Avatar}
                  src={item.avatar}
                  height={80}
                  width={80}
                />
                <Box marginTop={4}>
                  <ListItemText primary={item.name} secondary={item.title} />
                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                    {item.about}
                  </Typography>
                  {/* <Box marginTop={4}>
                    <IconButton size={'small'} color={'primary'}>
                      <FacebookIcon />
                    </IconButton>
                    <IconButton size={'small'} color={'primary'}>
                      <GitHubIcon />
                    </IconButton>
                    <IconButton size={'small'} color={'primary'}>
                      <TwitterIcon />
                    </IconButton>
                  </Box> */}
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
