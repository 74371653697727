export { default as AboutTop } from './AboutTop';
export { default as Advantages } from './Advantages';
export { default as Customers } from './Customers';
export { default as Features } from './Features';
export { default as Hero } from './Hero';
export { default as Jobs } from './Jobs';
export { default as Newsletter } from './Newsletter';
export { default as Partners } from './Partners';
export { default as Process } from './Process';
export { default as PromoNumbers } from './PromoNumbers';
export { default as Questions } from './Questions';
export { default as TrustedCompanies } from './TrustedCompanies';
