export { default as Welcome } from './Welcome';
export { default as Goby } from './Goby';
export { default as Process } from './Process';
export { default as Nike2 } from './Nike2';
export { default as Larq } from './Larq';
export { default as Nike } from './Nike';
export { default as Trek } from './Trek';
export { default as Curology } from './Curology';
export { default as Reviews } from './Reviews';
export { default as Contact } from './Contact';
