import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import office1 from '../../../../src_assets/our_team/office1.jpg';
import office2 from '../../../../src_assets/our_team/office2.jpg';
import office3 from '../../../../src_assets/our_team/office3.jpg';
import office4 from '../../../../src_assets/our_team/office4.jpg';

const Places = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: office1,
      source: office1,
      rows: 1,
      cols: 2,
    },
    {
      src: office2,
      source: office2,
      rows: 1,
      cols: 1,
    },
    {
      src: office3,
      source: office3,
      rows: 1,
      cols: 1,
    },
    {
      src: office4,
      source: office4,
      rows: 1,
      cols: 2,
    },
  ];
  const photosToShow = isMd ? photos : photos.slice(0, photos.length - 1);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Discover Our Culture
        </Typography>
        <Typography
          variant="h5"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          What do you like about working at PLAT.S?
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          We provide Latest MacBook provided,<br />
          We provide motion desks to all employees,<br />
          We provide delicious lunch at an affordable price at our in-house restaurant.,<br />
          We provide 4K 32 inch monitors.
    
        </Typography>        
      </Box>
      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 220}
          gap={isMd ? 16 : 8}
        >
          {photosToShow.map((item, i) => (
            <ImageListItem
              key={i}
              cols={isMd ? item.cols || 1 : 3}
              rows={isMd ? item.rows || 1 : 1}
            >
              <img
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                loading="lazy"
                style={{
                  objectFit: 'cover',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
};

export default Places;


