export { default as About } from './About';
export { default as AboutBottom } from './AboutBottom';
export { default as Features } from './Features';
export { default as Hero } from './Hero';
export { default as Integrations } from './Integrations';
export { default as Jobs } from './Jobs';
export { default as News } from './News';
export { default as Pricings } from './Pricings';
export { default as Reviews } from './Reviews';
export { default as Team } from './Team';
export { default as Trucking } from './Trucking';
export { default as Video } from './Video';
