import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Faq = () => {
  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'We\'ve collected some questions about PLAT.S'}
          items={[
            {
              title: 'What does PLAT.S\'s mean?',
              subtitle:
                'PLAT.S stands for platform search. It is a name created for the most important core technology search and data AI in platform business.',
            },
            {
              title: 'What are PLAT.S\'s main strengths?',
              subtitle:
                'PLAT.S is a company that deals with open source search engine-based technology. All members are search experts. Because data and machine learning technologies are closely related to search, we are researching related technologies.',
            },
            {
              title: 'Are you researching and developing search engine source technology?',
              subtitle:
                'No. We think the search engine is already at the peak of technology based on lucene. Therefore, re-developing the original technology does not fit the purpose of open source. Rather, we are focusing on developing solutions to utilize it more actively.',
            },
            {
              title: 'Why do search companies research technologies like ChatGPT?',
              subtitle:
                'Large Language Models like ChatGpt have a very similar operating concept to search engines. Additionally, in order to utilize a proper generative language model in a company, its combination with search engine technology is essential. Therefore, if you combine PLAT.S\'s search engine technology with technologies such as ChatGPT, you can develop a good solution that companies can use.',
            },            
          ]}
        />
      </Box>      
    </Box>
  );
};

export default Faq;
