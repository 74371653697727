const pages = {
  service: [
    {
      title: 'Intelligent Search',
      href: '/intelligent-search-1',
    },
    {
      title: 'Data analysis',
      href: '/data-analysis-1',
    },
    {
      title: 'Data collection',
      href: '/data-collection-1',
    },
    {
      title: 'Chatbot Development',
      href: '/chatbot-development',
    }    
  ],
  software: [
    {
      title: 'Search-Space v2',
      href: '/search-space',
    },
    {
      title: 'Chat-Ops-Studio',
      href: '/chat-ops-studio',
    },
    {
      title: 'Crawl-Ops-Studio',
      href: '/crawl-ops-studio',
    }
  ],
  casestudy: [
   
  ],
  partner: [
   
  ],
  aboutus: [
    {
      title: 'Who we are',
      href: '/who-we-are',
    },
    {
      title: 'Our Team',
      href: '/our-team',
    },
    {
      title: 'Contact',
      href: '/contact',
    },    
  ],

};

export default pages;
