export { default as About } from './About';
export { default as Articles } from './Articles';
export { default as Features } from './Features';
export { default as Hero } from './Hero';
export { default as HowItWorks } from './HowItWorks';
export { default as MobileApp } from './MobileApp';
export { default as Partners } from './Partners';
export { default as Pricings } from './Pricings';
export { default as Reviews } from './Reviews';
export { default as Storage } from './Storage';
export { default as Story } from './Story';
export { default as Support } from './Support';
