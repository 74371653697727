import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import logo from '../../../../src_assets/plats.logo.basic.png';

import { NavItem } from './components';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const {
    service: servicePages,
    software: softwarePages,
    aboutus: aboutusPages,
  } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="theFront"
        margin={3}
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={'img'}
          src={logo}
          height={0.8}
          width={0.8}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>
          <NavItem
            title={'Services'}
            id={'service-pages'}
            items={servicePages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Software'}
            id={'software-pages'}
            items={softwarePages}
            colorInvert={colorInvert}            
          />
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component="a"
            href="/case-study"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Case Study
          </Link>    
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component="a"
            href="/partners"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Partners
          </Link>          
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'About us'}
            id={'aboutus-pages'}
            items={aboutusPages}
            colorInvert={colorInvert}
          />
        </Box>        
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
