export { default as Advantages } from './Advantages';
export { default as Application } from './Application';
export { default as Community } from './Community';
export { default as Events } from './Events';
export { default as Hero } from './Hero';
export { default as Locations } from './Locations';
export { default as MapHero } from './MapHero';
export { default as Reviews } from './Reviews';
export { default as Spaces } from './Spaces';
export { default as Contact } from './Contact';
