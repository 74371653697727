export { default as Advantages } from './Advantages';
export { default as Articles } from './Articles';
export { default as AskExpert } from './AskExpert';
export { default as FeaturedProperties } from './FeaturedProperties';
export { default as Hero } from './Hero';
export { default as Partners } from './Partners';
export { default as Places } from './Places';
export { default as Reviews } from './Reviews';
export { default as Search } from './Search';
export { default as Teaser } from './Teaser';
