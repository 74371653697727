export { default as Hero } from './Hero';
export { default as Advantages } from './Advantages';
export { default as Services } from './Services';
export { default as QuickStart } from './QuickStart';
export { default as Features } from './Features';
export { default as Benefits } from './Benefits';
export { default as GetStarted } from './GetStarted';
export { default as Partners } from './Partners';
export { default as Contact } from './Contact';

